<template>
    <div
        class="modal fade"
        id="confirmLeaveModal"
        tabindex="-1"
        style="z-index: 1000000"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <p style="font-weight: 500" class="mb-2">
                        {{ t('appInvitation.cancel') }}
                    </p>
                    {{ t('appInvitation.once') }}
                </div>
                <div class="border-top">
                    <div class="d-flex flex-row px-2">
                        <a
                            data-bs-dismiss="modal"
                            class="w-50 border-end py-2"
                            style="font-weight: 500"
                        >
                            <div
                                @click="router.push('/')"
                                class="col text-center"
                            >
                                {{ t('appInvitation.leave') }}
                            </div>
                        </a>
                        <a
                            data-bs-dismiss="modal"
                            class="w-50 py-2"
                            style="font-weight: 600"
                        >
                            <div class="col text-center">
                                {{ t('appInvitation.stay') }}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <div class="d-flex flex-row align-items-center mb-3" style="gap: 0px">
            <h1 class="m-0 text-light">{{ t('appInvitation.welcome') }}</h1>
        </div>
        <div class="card p-3 shadow-sm">
            <div v-if="!loading">
                <div v-if="requestNewInvitation">
                    <div v-if="newEmailSent">
                        <h4>{{ t('appInvitation.sent') }}</h4>
                        <p>
                            {{ t('appInvitation.sentExplanation') }}
                        </p>
                        <router-link to="/" class="btn bg-light w-100">{{
                            t('misc.modal.done')
                        }}</router-link>
                    </div>
                    <div v-else>
                        <div
                            class="d-flex flex-row align-items-center mb-1"
                            style="gap: 0"
                        >
                            <button
                                @click="requestNewInvitation = false"
                                class="back-btn rounded"
                            >
                                <i class="fa fa-chevron-left"></i>
                            </button>
                            <h4 class="m-0">
                                {{ t('appInvitation.sendNew') }}
                            </h4>
                        </div>

                        <p>
                            {{ t('appInvitation.enterEmail') }}
                        </p>
                        <div class="mb-3">
                            <label for="email" class="form-label">{{
                                t('appInvitation.email')
                            }}</label>
                            <input
                                :class="{ 'is-invalid': emailNotFound }"
                                v-model="newEmailAddress"
                                @keyup.enter="sendNewLink"
                                type="text"
                                class="form-control"
                                id="email"
                                placeholder="Your e-mail registered to the account"
                            />
                            <div class="text-danger mt-1" v-if="emailNotFound">
                                {{ t('appInvitation.notFound') }}
                            </div>
                        </div>
                        <button
                            class="btn btn-primary w-100 mb-3"
                            @click="sendNewLink"
                        >
                            {{ t('appInvitation.sendNewLink') }}
                        </button>
                        <router-link class="btn bg-light w-100" to="/">{{
                            t('appInvitation.cancel')
                        }}</router-link>
                    </div>
                </div>
                <div v-else>
                    <div v-if="!error">
                        <div v-if="data">
                            <h4 class="mb-4">
                                {{ t('appInvitation.hi', { name: data.name }) }}
                            </h4>
                            <div class="mb-3">
                                <label for="email" class="form-label">{{
                                    t('appInvitation.email')
                                }}</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="id"
                                    v-model="data.email"
                                    disabled
                                />
                            </div>
                            <div class="mb-3">
                                <label for="newPassword" class="form-label">{{
                                    t('appInvitation.newPassword')
                                }}</label>
                                <input
                                    :class="{ 'is-invalid': passwordInvalid }"
                                    @keyup.enter="submit"
                                    type="text"
                                    class="form-control"
                                    id="newPassword"
                                    v-model="password"
                                    :placeholder="
                                        t('appInvitation.newPassword')
                                    "
                                />
                                <div v-if="passwordInvalid" class="text-danger">
                                    {{ t('appInvitation.eightChars') }}
                                </div>
                            </div>
                            <button
                                @click="submit"
                                class="btn btn-primary mb-3 w-100"
                            >
                                {{ t('appInvitation.setNew') }}
                            </button>
                            <button
                                data-bs-toggle="modal"
                                data-bs-target="#confirmLeaveModal"
                                class="btn bg-light w-100"
                            >
                                {{ t('appInvitation.cancel') }}
                            </button>
                        </div>
                    </div>
                    <div v-else>
                        <h4>{{ t('appInvitation.expiredHeading') }}</h4>
                        <p>
                            {{ t('appInvitation.sevenDays') }}
                        </p>
                        <button
                            @click="requestNewInvitation = true"
                            class="btn btn-primary w-100 mb-3"
                        >
                            {{ t('appInvitation.sendToMyEmail') }}
                        </button>
                        <button
                            class="btn bg-light w-100"
                            @click="router.push('/')"
                        >
                            {{ t('appInvitation.goBack') }}
                        </button>
                    </div>
                </div>
                <div class="mt-4 text-center">
                    <a
                        href="#"
                        @click.prevent="switchLanguage()"
                        class="text-primary mb-3"
                    >
                        <span v-if="i18n.locale.value == 'fr'"> English </span>
                        <span v-else> Francais </span>
                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <a class="dropdown-item" href="#">English</a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#">Français</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="d-flex flex-row justify-content-center align-items-center"
                style="min-height: 300px"
                v-else
            >
                <img
                    src="@/assets/loading-icon.gif"
                    style="width: 1.5rem; height: 1.5rem"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>
.back-btn {
    padding: 10px;
    padding-left: 0;
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: var(--bs-primary);
    width: 40px;
    height: 40px;
    justify-content: center;
}

.back-btn i {
    font-size: 1.4rem;
}
</style>

<script setup>
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import { onMounted, ref, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user-store';
const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
const { t } = useI18n();
const i18n = useI18n();
const route = useRoute();
const router = useRouter();
const data = ref(null);
const error = ref(false);
const submitError = ref(null);
const submitErrorMessage = ref(null);
const userStore = useUserStore();
const password = ref(null);
const loading = ref(null);
const requestNewInvitation = ref(false);
const newEmailAddress = ref(null);
const newEmailSent = ref(false);
const emailNotFound = ref(false);
const passwordInvalid = ref(false);

onMounted(() => {
    getInfo();
});

function switchLanguage() {
    if (i18n.locale.value == 'fr') {
        i18n.locale.value = 'en';
    } else {
        i18n.locale.value = 'fr';
    }
}

async function getInfo() {
    try {
        loading.value = true;
        error.value = false;
        const res = await axios.get(`invitation/${route.params.token}/info`);
        data.value = res.data;
        // log user out when they come to this page and fetched successfully
        userStore.clearUser();
        loading.value = false;
    } catch (err) {
        console.log(err);
        error.value = true;
        loading.value = false;
    }
}

async function submit() {
    try {
        passwordInvalid.value = false;
        loading.value = true;
        if (password.value.length < 8) {
            passwordInvalid.value = true;
            return;
        }
        await axios.post(`invitation/${route.params.token}/activate`, {
            password: password.value,
        });
        // after done, attempt login
        const resLogin = await axios.post('login', {
            email: data.value.email,
            password: password.value,
        });
        loading.value = false;
        userStore.setUserDetails(resLogin.data);
        router.push('/?dialog=account-activation');
    } catch (err) {
        passwordInvalid.value = false;
        loading.value = false;
        console.log(err.response.data);
        submitError.value = true;
        submitErrorMessage.value = err.response.data.message;
    }
}

async function sendNewLink() {
    try {
        loading.value = true;
        await axios.post('/invitation/request-new', {
            email: newEmailAddress.value,
        });
        loading.value = false;
        newEmailSent.value = true;
    } catch (err) {
        loading.value = false;
        if (err.response.data.error == 'email_not_found') {
            emailNotFound.value = true;
        }
    }
}
</script>
